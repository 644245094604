import { produce } from 'immer';
import { LoadingAction } from './actions';

export interface ILoadingState {
    actions: {
        [request: string]: {
            loading: boolean;
            error?: string;
        };
    };
    anyLoading: boolean;
    latestError: {
        message?: string;
        time?: number;
    };
}

const initialState: ILoadingState = {
    actions: {},
    anyLoading: false,
    latestError: {
        message: undefined,
        time: undefined,
    },
};

export const loadingReducer = produce((draft: ILoadingState, action: LoadingAction) => {
    switch (action.type) {
        case 'START_LOADING': {
            draft.actions[action.payload.request] = {
                loading: true,
                error: undefined,
            };
            break;
        }
        case 'LOADING_SUCCESS': {
            draft.actions[action.payload.request] = {
                loading: false,
                error: undefined,
            };
            break;
        }
        case 'LOADING_ERROR': {
            draft.actions[action.payload.request] = {
                loading: false,
                error: action.payload.error,
            };
            draft.latestError = {
                message: action.payload.error,
                time: Date.now(),
            };
        }
    }

    if (['START_LOADING', 'LOADING_SUCCESS', 'LOADING_ERROR'].includes(action.type)) {
        draft.anyLoading = Object.values(draft.actions)
            .map((a) => a.loading)
            .some(Boolean);
    }
}, initialState);
