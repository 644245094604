import { all, call, put, takeEvery } from 'redux-saga/effects';
import { ErrorLoadingActionCreator } from './actions';
import { enqueueSnackbar } from '../snack/actions';

function* notifyOnError() {
    yield takeEvery('' + ErrorLoadingActionCreator, function* (action: any) {
        yield put(enqueueSnackbar({ message: '' + action.payload.error, options: { variant: 'error' } }));
    });
}

export function* loadingSagas() {
    yield all([call(notifyOnError)]);
}
