import { takeEvery, put, all, call } from 'redux-saga/effects';
import { LoginSuccessActionCreator } from '../../auth/state/actions';
import { FetchIdentity } from './actions';

function* identification() {
    yield takeEvery('' + LoginSuccessActionCreator, function* () {
        yield put(FetchIdentity() as any);
    });
}

export function* profileSagas() {
    yield all([call(identification)]);
}
