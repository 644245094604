import React, { Suspense } from 'react';
import { History } from 'history';
import { ConnectedRouter } from 'connected-react-router';
import { TitleHost } from './modules/base/title';
import { CssBaseline } from '@material-ui/core';
import { Redirect, Route, Switch } from 'react-router';
import Base from './modules/base/Base';
import { LoginRoutes } from './modules/auth/Routes';
import { DashboardRoutes } from './modules/dashboard/Routes';
import { AssetRoutes } from './modules/asset/Routes';
import { ensureAuthenticated } from './modules/auth/wrapper';
import NotFound from './NotFound';
import CenterLoader from './components/CenterLoader';

const App: React.FunctionComponent<{ history: History }> = ({ history }) => {
    return (
        <ConnectedRouter history={history}>
            <TitleHost>
                <CssBaseline />
                <Base>
                    <Suspense fallback={<CenterLoader />}>
                        <Switch>
                            {[
                                <Redirect key="redirect" from="/" exact to="/searchAssets" />,
                                ...LoginRoutes(),
                                ...DashboardRoutes(),
                                ...AssetRoutes(),
                                <Route key="404" component={ensureAuthenticated(NotFound)} />,
                            ]}
                        </Switch>
                    </Suspense>
                </Base>
            </TitleHost>
        </ConnectedRouter>
    );
};

export default App;
