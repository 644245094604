import { Box, CircularProgress, makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(() => ({
    center: {
        display: 'flex',
        justifyContent: 'center',
        height: '100%',
        alignItems: 'center',
    },
}));

const CenterLoader: React.FunctionComponent = () => {
    const classes = useStyles({});
    return (
        <Box className={classes.center}>
            <CircularProgress />
        </Box>
    );
};

export default CenterLoader;
