import { List } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import MenuPopupItem from './MenuPopupItem';
import { RootState } from '../../../state/rootReducer';

export const NavBar = () => {
    const nav = useSelector((state: RootState) => state.base.nav);
    return (
        <List disablePadding>
            {Object.values(nav.itemsById)
                .filter((n: any) => nav.rootIds.includes(n.id))
                .sort((a: any, b: any) => {
                    return a.sort_order - b.sort_order;
                })
                .map((n: any) => (
                    <MenuPopupItem item={n} key={n.id} />
                ))}
        </List>
    );
};
