import { Divider, ListItem, ListItemText, makeStyles, MenuItem, Theme, useTheme, Typography, useMediaQuery, ListItemIcon } from '@material-ui/core';
import * as React from 'react';
import { bindHover, bindMenu, usePopupState } from 'material-ui-popup-state/hooks';
import Menu from 'material-ui-popup-state/HoverMenu';
import { matchPath, useLocation, useRouteMatch } from 'react-router';
import HomeIcon from '@material-ui/icons/Home';
import FolderIcon from '@material-ui/icons/Folder';
import AddIcon from '@material-ui/icons/Add';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import { Link, NavLink } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Immutable } from 'immer';
import { RootState } from '../../../state/rootReducer';
import { SetNavExpandedActionCreator } from '../state/actions';
import { INavItem } from '../state/reducer';

export const mobile_scaling_factor = 0.65;

const icon_scaling_factor = 0.15;

const IconWrapper: React.FC<{ fontSize?: number; otherStyleProps?: Record<string, unknown>; icon: any }> = ({ fontSize = 24, otherStyleProps = {}, icon: Component }) => {
    const theme = useTheme();
    const is_small = useMediaQuery(theme.breakpoints.down('sm'));

    const scaledFontSize = fontSize * (is_small ? mobile_scaling_factor * (1 + icon_scaling_factor) : 1);

    return <Component style={{ fontSize: scaledFontSize, ...otherStyleProps }} />;
};

const iconMap: Record<string, React.FunctionComponent> = {
    dashboard: () => <IconWrapper icon={HomeIcon} />,
    folder: () => <IconWrapper icon={FolderIcon} />,
    add: () => <IconWrapper icon={AddIcon} />,
    tree: () => <IconWrapper icon={AccountTreeIcon} />,
};

const withStyles = makeStyles((theme: Theme) => ({
    muiList: {
        padding: '0 !important',
    },
    links: {
        color: theme.palette.text.primary,
        textDecoration: 'none',
    },
    listItem: {
        paddingLeft: theme.spacing(2),
        paddingTop: theme.spacing(1.2),
        paddingBottom: theme.spacing(1.2),
        [theme.breakpoints.down('sm')]: {
            paddingLeft: theme.spacing(2) * mobile_scaling_factor * (1 - icon_scaling_factor),
            paddingTop: theme.spacing(1.2) * mobile_scaling_factor * (1 - icon_scaling_factor),
            paddingBottom: theme.spacing(1.2) * mobile_scaling_factor * (1 - icon_scaling_factor),
        },
    },
}));

const withChildStyles = makeStyles((theme: Theme) => ({
    headingStyle: {
        fontWeight: 'bold',
    },
    leafStyle: {
        fontWeight: 'lighter',
    },
    links: {
        color: theme.palette.text.primary,
        textDecoration: 'none',
    },
    subMenu: {
        [theme.breakpoints.down('sm')]: {
            paddingLeft: theme.spacing(2),
        },
    },
    menuItem: {
        paddingTop: theme.spacing(1.4),
        paddingBottom: theme.spacing(1.4),
    },
}));

function renderIcon(Icon: any) {
    return <Icon />;
}

const MenuItemRow: React.FunctionComponent<{ route: RootState['base']['nav']['itemsById'][''] }> = ({ route }) => {
    const styles = withChildStyles({});
    const match = useRouteMatch(route.href || '');
    const theme = useTheme();
    const is_small = useMediaQuery(theme.breakpoints.down('sm'));

    const dispatch = useDispatch();
    console.log(route);
    return (
        <Link
            key={route.href}
            className={styles.links}
            to={route.href || ''}
            onClick={() => {
                if (is_small) {
                    dispatch(SetNavExpandedActionCreator(false));
                }
            }}
        >
            <MenuItem dense={is_small} className={styles.menuItem} selected={match?.isExact} disabled={!route.enabled}>
                {!route.disable_icon && route.icon ? iconMap[route.icon] : null}
                <Typography className={route.children.length > 0 ? styles.headingStyle : styles.leafStyle}>{route.title}</Typography>
            </MenuItem>
        </Link>
    );
};

const MenuSubItem = ({ ids, idMap }: { ids: readonly string[]; idMap: RootState['base']['nav']['itemsById'] }) => {
    const styles = withChildStyles({});

    return (
        <div className={styles.subMenu}>
            {ids
                .map((childId) => idMap[childId])
                .map((childRoute, idx) => (
                    <>
                        {childRoute.divided ? idx > 0 ? <Divider /> : null : <MenuItemRow route={childRoute} />}
                        {childRoute.children.length > 0 ? <MenuSubItem ids={childRoute.children} idMap={idMap} /> : null}
                    </>
                ))}
        </div>
    );
};

const MenuPopupItem = ({ item }: { item: Immutable<INavItem> }) => {
    // TODO: Fix popup menu
    const popupState = usePopupState({ variant: 'popover', popupId: 'demoMenu' });
    const classes = withStyles({});

    const navbarExpanded = useSelector((state: RootState) => state.base.nav.expanded);
    const nav = useSelector((state: RootState) => state.base.nav);
    const dispatch = useDispatch();

    const match = useRouteMatch('*');

    React.useEffect(() => popupState.close, [match?.url, popupState.close]);

    const theme = useTheme();
    const is_small = useMediaQuery(theme.breakpoints.down('sm'));

    const location = useLocation();

    return (
        <>
            <ListItem
                component={NavLink as any}
                key={item.href}
                to={item.href}
                disabled={item.enabled === false}
                onClick={() => {
                    if (item.children.length < 1 && is_small) {
                        dispatch(SetNavExpandedActionCreator(false));
                    }
                }}
                className={classes.listItem}
                disableGutters
                button
                {...bindHover(popupState)}
                selected={
                    // TODO: Wtf is location?
                    // eslint-disable-next-line no-restricted-globals
                    !!matchPath(location.pathname, {
                        path: item.href,
                        exact: false,
                    })
                }
            >
                {item.icon && iconMap[item.icon] ? <ListItemIcon>{renderIcon(iconMap[item.icon])}</ListItemIcon> : !item.enabled ? iconMap.blocked : null}
                <ListItemText primary={item.title} />
            </ListItem>
            {navbarExpanded &&
                item.children.length > 0 &&
                !!matchPath(location.pathname, {
                    path: item.href,
                    exact: false,
                }) && (
                    <div style={{ borderLeft: `4px solid ${theme.palette.divider}` }}>
                        <MenuSubItem ids={item.children} idMap={nav.itemsById} />
                    </div>
                )}
            {item.children.length > 0 && !navbarExpanded && !is_small && (
                <Menu
                    {...bindMenu(popupState)}
                    getContentAnchorEl={null}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                >
                    <MenuSubItem ids={item.children} idMap={nav.itemsById} />
                </Menu>
            )}
        </>
    );
};

export default MenuPopupItem;
