import { makeStyles, Theme } from '@material-ui/core';
import React from 'react';
import { ProvidePageTitle } from './title';
import { SnackbarProvider } from 'notistack';
import SnackDisplay from './snackDisplay';
import { MainLayout } from './components/Frame';
import { NavBar } from './components/navBar';
import { TopNav } from './components/topNav';
import { useRouteMatch } from 'react-router';

const useStyles = makeStyles((theme: Theme) => ({
    '@global': {
        '::-webkit-scrollbar': {
            width: '1.4em',
            height: '1.4em',
        },
        '::-webkit-scrollbar-thumb': {
            borderRadius: 7,
            borderWidth: 3,
            borderColor: 'rgba(0,0,0,0)',
            borderStyle: 'solid',
            background: 'rgba(0,0,0,0.4)',
            backgroundClip: 'padding-box',
            '&:hover': {
                background: 'rgba(0,0,0,0.7)',
                backgroundClip: 'padding-box',
            },
        },
    },
    root: {
        height: '100%',
        backgroundColor: theme.palette.background.paper,
        // overflowY: 'auto',
        // paddingBottom: theme.spacing(6),
    },
}));

const Base: React.FunctionComponent = ({ children }) => {
    const classes = useStyles();

    const content = <div className={classes.root}>{children}</div>;
    const contentWithNav = (
        <div className={classes.root}>
            <MainLayout topnav_children={<TopNav />} drawer_menu_children={<NavBar />}>
                {content}
            </MainLayout>
        </div>
    );
    const isLogin = useRouteMatch('/login');

    return (
        <SnackbarProvider maxSnack={3}>
            <SnackDisplay />
            <ProvidePageTitle>{'Resepti'}</ProvidePageTitle>
            {isLogin ? content : contentWithNav}
        </SnackbarProvider>
    );
};

export default Base;
