import { produce } from 'immer';
import { AuthActions, ISetAuthTokenPayload } from './actions';

export interface IAuthState {
    logged_in: boolean;
    token: ISetAuthTokenPayload;
}

const initialState: IAuthState = {
    logged_in: false,
    token: {
        access_token: '',
        expires_on: 0,
    },
};

export const authReducer = produce((draft: IAuthState, action: AuthActions) => {
    switch (action.type) {
        case 'SET_AUTH_TOKEN': {
            draft.token = action.payload;
            break;
        }
        case 'LOGIN_SUCCESS': {
            draft.logged_in = true;
            break;
        }
        case 'LOGOUT': {
            draft.token = initialState.token;
            draft.logged_in = false;
            break;
        }
    }
}, initialState);
