import { actionCreator } from '../utils';
import { ISnack } from './reducer';

export const EnqueueSnackbarActionCreator = actionCreator<'ENQUEUE_SNACK', ISnack>('ENQUEUE_SNACK');
export const CloseSnackbarActionCreator = actionCreator<'CLOSE_SNACKBAR', { key?: string }>('CLOSE_SNACKBAR');
export const RemoveSnackbarActionCreator = actionCreator<'REMOVE_SNACKBAR', { key: string }>('REMOVE_SNACKBAR');

export type SnackAction = ReturnType<typeof EnqueueSnackbarActionCreator> | ReturnType<typeof CloseSnackbarActionCreator> | ReturnType<typeof RemoveSnackbarActionCreator>;

export function enqueueSnackbar(notification: Omit<ISnack, 'key'>) {
    const key = new Date().getTime() + Math.random();

    return EnqueueSnackbarActionCreator({
        ...notification,
        key: '' + key,
    });
}
