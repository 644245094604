import { actionCreator, ActionTypeHelper } from '../../../state/utils';
import { WrapLoading } from '../../../state/loading/actions';
import Cookies from 'js-cookie';
import { TOKEN_KEY } from './saga';
import { goBack, push } from 'connected-react-router';
import { enqueueSnackbar } from '../../../state/snack/actions';
import { ApolloClient, InMemoryCache, NormalizedCacheObject } from '@apollo/client';
import { BatchHttpLink } from '@apollo/link-batch-http/lib/batchHttpLink';
import {
    CreateUser,
    CreateUserMutation,
    CreateUserMutationVariables,
    RequestPasswordReset,
    RequestPasswordResetMutation,
    RequestPasswordResetMutationVariables,
    ResetPassword,
    ResetPasswordMutation,
    ResetPasswordMutationVariables,
} from '../../../generated/graphql';

export interface ISetAuthTokenPayload {
    access_token: string;
    expires_on: number;
}

export const LogoutActionCreator = actionCreator<'LOGOUT'>('LOGOUT');
export const LoginSuccessActionCreator = actionCreator<'LOGIN_SUCCESS'>('LOGIN_SUCCESS');
export const SetAuthTokenActionCreator = actionCreator<'SET_AUTH_TOKEN', ISetAuthTokenPayload>('SET_AUTH_TOKEN');
export const CreateUserActionCreator = actionCreator<'CREATE_USER', CreateUserMutation['addUser']>('CREATE_USER');
export const RequestResetUserPasswordActionCreator = actionCreator<'REQUEST_RESET_PASSWORD', RequestPasswordResetMutation['requestPasswordReset']>('REQUEST_RESET_PASSWORD');
export const ResetUserPassword = actionCreator<'RESET_PASSWORD', ResetPasswordMutation['resetPassword']>('RESET_PASSWORD');

export type AuthActions =
    | ReturnType<typeof LoginSuccessActionCreator>
    | ReturnType<typeof LogoutActionCreator>
    | ReturnType<typeof SetAuthTokenActionCreator>
    | ReturnType<typeof CreateUserActionCreator>
    | ReturnType<typeof RequestResetUserPasswordActionCreator>
    | ReturnType<typeof ResetUserPassword>;

export const LOGIN_LOADING_ACTION = 'login_loading_action';

export function LoginUsernamePassword(email: string, password: string): ActionTypeHelper<Promise<void>> {
    return WrapLoading(
        LOGIN_LOADING_ACTION,
        async (dispatch) => {
            dispatch(LogoutActionCreator());
            const options = {
                method: 'post',
                body: JSON.stringify({
                    email,
                    password,
                }),
                headers: { 'Content-Type': 'application/json', accept: 'application/json' },
            };
            const resp = await fetch(`${process.env.REACT_APP_BACKEND_URI}/auth/login`, options);
            if (resp.status !== 200) {
                if (resp.status === 401) {
                    dispatch(enqueueSnackbar({ message: 'Username or password is incorrect', options: { variant: 'error' } }));
                    return;
                }
                throw new Error(await resp.text());
            }
            const response = await resp.json();
            console.log('Login successful!');
            dispatch(
                SetAuthTokenActionCreator({
                    access_token: response.access_token,
                    expires_on: Date.now() + (parseInt(response.valid_for) - 5000), //minus 5 seconds for any sychronization differences
                }),
            );
            dispatch(LoginSuccessActionCreator());
        },
        true,
        true,
        true,
    );
}

export function CheckToken(): ActionTypeHelper<Promise<void>> {
    return async (dispatch, getState) => {
        let token: string | undefined;

        token = getState().auth.token.access_token;
        if (!token) {
            token = Cookies.get(TOKEN_KEY);
        }
        const tokenValid = Date.now() < getState().auth.token.expires_on;

        const location = getState().router.location;
        if (!token || !tokenValid) {
            console.log('No stored auth found. The user must log in');
            dispatch(push(`/login?redirect=${encodeURIComponent(`${location.pathname}?${location.search}`)}`));
        } else {
            try {
                console.log('Stored auth found');
                dispatch(LoginSuccessActionCreator()); //TODO: check token expiration
            } catch (e) {
                console.log(`Failed to refresh. The user must re-login. Error: ${e}`);
                dispatch(push(`/login?redirect=${encodeURIComponent(`${location.pathname}?${location.search}`)}`));
            }
        }
    };
}

export function GetGraphQLClient(auth = true): ActionTypeHelper<ApolloClient<NormalizedCacheObject>> {
    return (dispatch, getState) => {
        if (auth) {
            //need to re-auth
            if (Date.now() > getState().auth.token.expires_on) {
                dispatch(LogoutActionCreator());
                throw new Error(`Access token has expired`);
            }
        }

        const link = new BatchHttpLink({
            uri: `${process.env.REACT_APP_BACKEND_URI}/graphql`,
            fetch,
            headers: {
                Authorization: auth ? `Bearer ${getState().auth.token.access_token}` : null,
            },
        });
        return new ApolloClient({
            link,
            cache: new InMemoryCache({
                addTypename: false,
            }),

            defaultOptions: {
                watchQuery: {
                    fetchPolicy: 'no-cache',
                },
                query: {
                    fetchPolicy: 'no-cache',
                    errorPolicy: 'all',
                },
            },
        });
    };
}

export const CREATE_ACCOUNT_LOADING = 'create_account_loading';

export function CreateAccountAction(args: CreateUserMutationVariables): ActionTypeHelper<Promise<void>> {
    return WrapLoading(CREATE_ACCOUNT_LOADING, async (dispatch, getState) => {
        const client = GetGraphQLClient(false)(dispatch, getState, undefined);
        const response = await client.mutate<CreateUserMutation, CreateUserMutationVariables>({
            mutation: CreateUser,
            variables: args,
        });
        if (response.errors) {
            throw new Error(response.errors.map((e) => e.message).join('\n'));
        }

        dispatch(enqueueSnackbar({ message: 'Successfully created account', options: { variant: 'success' } }));
        dispatch(goBack());
    });
}

export const PASSWORD_RESET_LOADING = 'password_reset_loading';

export function ResetPasswordAction(token: string, password: string): ActionTypeHelper<Promise<boolean>> {
    return WrapLoading(PASSWORD_RESET_LOADING, async (dispatch, getState) => {
        const client = GetGraphQLClient(false)(dispatch, getState, undefined);
        const response = await client.query<ResetPasswordMutation, ResetPasswordMutationVariables>({
            query: ResetPassword,
            variables: {
                token,
                input: { password },
            },
        });

        if (response.errors) {
            throw new Error(response.errors.map((e) => e.message).join('\n'));
        }
        const { resetPassword } = response.data;

        return resetPassword;
    });
}

export const REQUEST_PASSWORD_RESET_LOADING = 'request_password_reset_loading';

export function RequestResetPasswordAction(email: string): ActionTypeHelper<Promise<boolean>> {
    return WrapLoading(REQUEST_PASSWORD_RESET_LOADING, async (dispatch, getState) => {
        const client = GetGraphQLClient(false)(dispatch, getState, undefined);
        const response = await client.query<RequestPasswordResetMutation, RequestPasswordResetMutationVariables>({
            query: RequestPasswordReset,
            variables: {
                input: { email },
            },
        });

        if (response.errors) {
            throw new Error(response.errors.map((e) => e.message).join('\n'));
        }
        const { requestPasswordReset } = response.data;

        return requestPasswordReset;
    });
}

export const initialActions = [CheckToken()];
