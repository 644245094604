import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: string;
    String: string;
    Boolean: boolean;
    Int: number;
    Float: number;
    /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
    DateTime: any;
    /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
    JSON: any;
};

export type AddAssetInput = {
    name: Scalars['String'];
    parent?: Maybe<Scalars['ID']>;
    metadata?: Maybe<AssetMetadataInput>;
    tags: Array<Scalars['String']>;
};

export type AddUserInput = {
    firstName?: Maybe<Scalars['String']>;
    lastName?: Maybe<Scalars['String']>;
    email: Scalars['String'];
    password: Scalars['String'];
};

export type Asset = {
    id: Scalars['ID'];
    created_at: Scalars['DateTime'];
    updated_at: Scalars['DateTime'];
    name: Scalars['String'];
    parent?: Maybe<Asset>;
    children: Array<Asset>;
    permissions: Array<AssetPermission>;
    owner: User;
    metadata: Scalars['JSON'];
    tags: Array<Scalars['String']>;
};

export type AssetMetadataInput = {
    name: Scalars['String'];
    type: AssetMetadataTypes;
    data: Scalars['JSON'];
};

export enum AssetMetadataTypes {
    String = 'STRING',
    Number = 'NUMBER',
    Date = 'DATE',
    Boolean = 'BOOLEAN',
    Image = 'IMAGE',
}

export type AssetPermission = {
    id: Scalars['ID'];
    created_at: Scalars['DateTime'];
    updated_at: Scalars['DateTime'];
    asset: Asset;
    target: User;
    permissions: Array<AssetPermissionType>;
};

export enum AssetPermissionType {
    View = 'VIEW',
    Edit = 'EDIT',
    Delete = 'DELETE',
}

export type Image = {
    name: Scalars['String'];
    url: Scalars['String'];
    createdAt: Scalars['DateTime'];
    expirationAt: Scalars['DateTime'];
};

export type ModifyAssetInput = {
    name?: Maybe<Scalars['String']>;
    parent?: Maybe<Scalars['ID']>;
    metadata?: Maybe<Array<AssetMetadataInput>>;
};

export type Mutation = {
    addUser: User;
    requestPasswordReset: Scalars['Boolean'];
    resetPassword: Scalars['Boolean'];
    addAsset: Asset;
    editAsset: Asset;
    setAssetTags: Asset;
    deleteAsset: Asset;
    upsertAssetPermission: AssetPermission;
    deleteAssetPermission: AssetPermission;
    putImageURI: Scalars['String'];
    removeImage: Scalars['Boolean'];
};

export type MutationAddUserArgs = {
    user: AddUserInput;
};

export type MutationRequestPasswordResetArgs = {
    input: PasswordResetInput;
};

export type MutationResetPasswordArgs = {
    input: PasswordChangeReset;
    token: Scalars['String'];
};

export type MutationAddAssetArgs = {
    asset: AddAssetInput;
};

export type MutationEditAssetArgs = {
    asset: ModifyAssetInput;
    id: Scalars['ID'];
};

export type MutationSetAssetTagsArgs = {
    tags: Array<Scalars['String']>;
    id: Scalars['ID'];
};

export type MutationDeleteAssetArgs = {
    id: Scalars['ID'];
};

export type MutationUpsertAssetPermissionArgs = {
    permissions: Array<AssetPermissionType>;
    target_user: Scalars['ID'];
    asset_id: Scalars['ID'];
};

export type MutationDeleteAssetPermissionArgs = {
    id: Scalars['ID'];
};

export type MutationPutImageUriArgs = {
    name: Scalars['String'];
};

export type MutationRemoveImageArgs = {
    name: Scalars['String'];
};

export type PasswordChangeReset = {
    password: Scalars['String'];
};

export type PasswordResetInput = {
    email: Scalars['String'];
};

export type Query = {
    getUser: User;
    getSelf: User;
    getAsset: Asset;
    getAssets: Array<Asset>;
    searchAssets: Array<Asset>;
    getAssetPermission: AssetPermission;
    getImageURI: Image;
};

export type QueryGetUserArgs = {
    email?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['ID']>;
};

export type QueryGetAssetArgs = {
    id: Scalars['String'];
};

export type QueryGetAssetsArgs = {
    only_owned?: Maybe<Scalars['Boolean']>;
};

export type QuerySearchAssetsArgs = {
    metadata?: Maybe<Scalars['JSON']>;
    only_owned?: Maybe<Scalars['Boolean']>;
    name: Scalars['String'];
};

export type QueryGetAssetPermissionArgs = {
    id: Scalars['ID'];
};

export type QueryGetImageUriArgs = {
    name: Scalars['String'];
};

export type User = {
    id: Scalars['ID'];
    created_at: Scalars['DateTime'];
    updated_at: Scalars['DateTime'];
    firstName: Scalars['String'];
    lastName: Scalars['String'];
    email: Scalars['String'];
    verified: Scalars['Boolean'];
    assets: Array<Asset>;
};

export type CreateAssetMutationVariables = Exact<{
    asset: AddAssetInput;
}>;

export type CreateAssetMutation = {
    addAsset: { id: string; name: string; metadata: any; created_at: any; updated_at: any; parent?: Maybe<{ id: string; name: string }>; owner: { id: string; firstName: string } };
};

export type ModifyAssetMutationVariables = Exact<{
    id: Scalars['ID'];
    asset: ModifyAssetInput;
}>;

export type ModifyAssetMutation = {
    editAsset: {
        id: string;
        name: string;
        metadata: any;
        created_at: any;
        updated_at: any;
        parent?: Maybe<{ id: string; name: string }>;
        owner: { id: string; firstName: string };
    };
};

export type SetAssetTagsMutationVariables = Exact<{
    id: Scalars['ID'];
    tags: Array<Scalars['String']> | Scalars['String'];
}>;

export type SetAssetTagsMutation = {
    setAssetTags: {
        id: string;
        name: string;
        metadata: any;
        created_at: any;
        updated_at: any;
        parent?: Maybe<{ id: string; name: string }>;
        owner: { id: string; firstName: string };
    };
};

export type DeleteAssetMutationVariables = Exact<{
    id: Scalars['ID'];
}>;

export type DeleteAssetMutation = {
    deleteAsset: {
        id: string;
        name: string;
        metadata: any;
        created_at: any;
        updated_at: any;
        parent?: Maybe<{ id: string; name: string }>;
        owner: { id: string; firstName: string };
    };
};

export type UpsertAssetPermissionMutationVariables = Exact<{
    assetId: Scalars['ID'];
    targetUser: Scalars['ID'];
    permissions: Array<AssetPermissionType> | AssetPermissionType;
}>;

export type UpsertAssetPermissionMutation = {
    upsertAssetPermission: { id: string; permissions: Array<AssetPermissionType>; target: { id: string; email: string; firstName: string; lastName: string } };
};

export type DeleteAssetPermissionMutationVariables = Exact<{
    permId: Scalars['ID'];
}>;

export type DeleteAssetPermissionMutation = {
    deleteAssetPermission: { id: string; permissions: Array<AssetPermissionType>; target: { id: string; email: string; firstName: string; lastName: string } };
};

export type SearchAssetsQueryVariables = Exact<{
    name: Scalars['String'];
    metadata?: Maybe<Scalars['JSON']>;
    only_owned?: Maybe<Scalars['Boolean']>;
}>;

export type SearchAssetsQuery = {
    searchAssets: Array<{
        id: string;
        name: string;
        metadata: any;
        created_at: any;
        updated_at: any;
        parent?: Maybe<{ id: string; name: string }>;
        owner: { id: string; firstName: string };
    }>;
};

export type GetAssetQueryVariables = Exact<{
    id: Scalars['String'];
}>;

export type GetAssetQuery = {
    getAsset: {
        id: string;
        name: string;
        metadata: any;
        tags: Array<string>;
        created_at: any;
        updated_at: any;
        parent?: Maybe<{ id: string; name: string }>;
        owner: { id: string; firstName: string };
        permissions: Array<{ id: string; permissions: Array<AssetPermissionType>; target: { id: string; email: string; firstName: string; lastName: string } }>;
        children: Array<{ id: string; name: string; metadata: any; created_at: any; updated_at: any; owner: { id: string; firstName: string } }>;
    };
};

export type GetAssetsQueryVariables = Exact<{
    only_owned?: Maybe<Scalars['Boolean']>;
}>;

export type GetAssetsQuery = {
    getAssets: Array<{
        id: string;
        name: string;
        metadata: any;
        created_at: any;
        updated_at: any;
        parent?: Maybe<{ id: string; name: string }>;
        owner: { id: string; firstName: string };
    }>;
};

export type CreateUserMutationVariables = Exact<{
    user: AddUserInput;
}>;

export type CreateUserMutation = { addUser: { id: string; firstName: string; lastName: string; email: string } };

export type ResetPasswordMutationVariables = Exact<{
    token: Scalars['String'];
    input: PasswordChangeReset;
}>;

export type ResetPasswordMutation = { resetPassword: boolean };

export type RequestPasswordResetMutationVariables = Exact<{
    input: PasswordResetInput;
}>;

export type RequestPasswordResetMutation = { requestPasswordReset: boolean };

export type GetMeQueryVariables = Exact<{ [key: string]: never }>;

export type GetMeQuery = { getSelf: { id: string; firstName: string; lastName: string; email: string } };

export type GetUserQueryVariables = Exact<{
    id?: Maybe<Scalars['ID']>;
    email?: Maybe<Scalars['String']>;
}>;

export type GetUserQuery = { getUser: { firstName: string; id: string; created_at: any } };

export type PutImageUriMutationVariables = Exact<{
    name: Scalars['String'];
}>;

export type PutImageUriMutation = { putImageURI: string };

export type RemoveImageMutationVariables = Exact<{
    name: Scalars['String'];
}>;

export type RemoveImageMutation = { removeImage: boolean };

export type GetImageUriQueryVariables = Exact<{
    name: Scalars['String'];
}>;

export type GetImageUriQuery = { getImageURI: { name: string; url: string; createdAt: any; expirationAt: any } };

export const CreateAsset = gql`
    mutation createAsset($asset: AddAssetInput!) {
        addAsset(asset: $asset) {
            id
            name
            metadata
            parent {
                id
                name
            }
            owner {
                id
                firstName
            }
            created_at
            updated_at
        }
    }
`;
export const ModifyAsset = gql`
    mutation modifyAsset($id: ID!, $asset: ModifyAssetInput!) {
        editAsset(id: $id, asset: $asset) {
            id
            name
            metadata
            parent {
                id
                name
            }
            owner {
                id
                firstName
            }
            created_at
            updated_at
        }
    }
`;
export const SetAssetTags = gql`
    mutation setAssetTags($id: ID!, $tags: [String!]!) {
        setAssetTags(id: $id, tags: $tags) {
            id
            name
            metadata
            parent {
                id
                name
            }
            owner {
                id
                firstName
            }
            created_at
            updated_at
        }
    }
`;
export const DeleteAsset = gql`
    mutation deleteAsset($id: ID!) {
        deleteAsset(id: $id) {
            id
            name
            metadata
            parent {
                id
                name
            }
            owner {
                id
                firstName
            }
            created_at
            updated_at
        }
    }
`;
export const UpsertAssetPermission = gql`
    mutation upsertAssetPermission($assetId: ID!, $targetUser: ID!, $permissions: [AssetPermissionType!]!) {
        upsertAssetPermission(asset_id: $assetId, target_user: $targetUser, permissions: $permissions) {
            id
            target {
                id
                email
                firstName
                lastName
            }
            permissions
        }
    }
`;
export const DeleteAssetPermission = gql`
    mutation deleteAssetPermission($permId: ID!) {
        deleteAssetPermission(id: $permId) {
            id
            target {
                id
                email
                firstName
                lastName
            }
            permissions
        }
    }
`;
export const SearchAssets = gql`
    query SearchAssets($name: String!, $metadata: JSON, $only_owned: Boolean) {
        searchAssets(name: $name, metadata: $metadata, only_owned: $only_owned) {
            id
            name
            metadata
            parent {
                id
                name
            }
            owner {
                id
                firstName
            }
            created_at
            updated_at
        }
    }
`;
export const GetAsset = gql`
    query GetAsset($id: String!) {
        getAsset(id: $id) {
            id
            name
            metadata
            parent {
                id
                name
            }
            owner {
                id
                firstName
            }
            permissions {
                id
                target {
                    id
                    email
                    firstName
                    lastName
                }
                permissions
            }
            children {
                id
                name
                metadata
                owner {
                    id
                    firstName
                }
                created_at
                updated_at
            }
            tags
            created_at
            updated_at
        }
    }
`;
export const GetAssets = gql`
    query GetAssets($only_owned: Boolean) {
        getAssets(only_owned: $only_owned) {
            id
            name
            metadata
            parent {
                id
                name
            }
            owner {
                id
                firstName
            }
            created_at
            updated_at
        }
    }
`;
export const CreateUser = gql`
    mutation createUser($user: AddUserInput!) {
        addUser(user: $user) {
            id
            firstName
            lastName
            email
        }
    }
`;
export const ResetPassword = gql`
    mutation resetPassword($token: String!, $input: PasswordChangeReset!) {
        resetPassword(token: $token, input: $input)
    }
`;
export const RequestPasswordReset = gql`
    mutation requestPasswordReset($input: PasswordResetInput!) {
        requestPasswordReset(input: $input)
    }
`;
export const GetMe = gql`
    query getMe {
        getSelf {
            id
            firstName
            lastName
            email
        }
    }
`;
export const GetUser = gql`
    query getUser($id: ID, $email: String) {
        getUser(id: $id, email: $email) {
            firstName
            id
            created_at
        }
    }
`;
export const PutImageUri = gql`
    mutation putImageURI($name: String!) {
        putImageURI(name: $name)
    }
`;
export const RemoveImage = gql`
    mutation removeImage($name: String!) {
        removeImage(name: $name)
    }
`;
export const GetImageUri = gql`
    query getImageURI($name: String!) {
        getImageURI(name: $name) {
            name
            url
            createdAt
            expirationAt
        }
    }
`;
