import { produce } from 'immer';
import { GetAssetQuery, SearchAssetsQuery } from '../../../generated/graphql';
import { Immutable } from '../../../state/utils';
import { AssetActions } from './actions';

type ArrayElement<ArrayType extends readonly unknown[]> = ArrayType[number];

export type IAssetNoChild = Immutable<ArrayElement<SearchAssetsQuery['searchAssets']>>;
export type IAsset = IAssetNoChild & { children?: IAssetNoChild[] } & { permissions?: GetAssetQuery['getAsset']['permissions'] } & { tags?: string[] };

export interface IAssetState {
    searchAssets?: IAsset[];
    asset?: IAsset;
    allAssets?: IAsset[];
}

const initialState: IAssetState = {
    searchAssets: [],
};

export const assetReducer = produce((draft: IAssetState, action: AssetActions) => {
    switch (action.type) {
        case 'SEARCH_ASSETS': {
            draft.searchAssets = action.payload;
            break;
        }
        case 'GET_ASSET': {
            draft.asset = action.payload;
            break;
        }
        case 'GET_ASSETS': {
            draft.allAssets = action.payload;
            break;
        }
    }
}, initialState);
