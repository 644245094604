import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './App';
import reportWebVitals from './reportWebVitals';
import LuxonUtils from '@date-io/luxon';
import './global_styles.css';
import { history, rootStore } from './state/store';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

/* <React.StrictMode> */

ReactDOM.render(
    <Provider store={rootStore}>
        <MuiPickersUtilsProvider utils={LuxonUtils}>
            <App history={history} />
        </MuiPickersUtilsProvider>
    </Provider>,
    document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
