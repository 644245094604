import { produce } from 'immer';
import { Image } from '../../../generated/graphql';
import { RootState } from '../../../state/rootReducer';
import { StorageActions } from './actions';

export interface IStorageState {
    imageURIs: Record<string, Image>;
}

const initialState: IStorageState = { imageURIs: {} };

export const storageReducer = produce((draft: IStorageState, action: StorageActions) => {
    switch (action.type) {
        case 'GET_IMAGE_URI': {
            draft.imageURIs[action.payload.name] = action.payload;
            break;
        }
    }
}, initialState);

export const getCachedImageURI = (name: string, getState: () => RootState): Image | undefined => {
    return getState().storage.imageURIs[name];
};

export const checkCachedImageURI = (name: string, getState: () => RootState) => {
    const cachedValue = getCachedImageURI(name, getState);
    if (!cachedValue) {
        return false;
    } else if (new Date(cachedValue.expirationAt) < new Date()) {
        return false;
    }
    return true;
};
