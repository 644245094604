import { combineReducers } from '@reduxjs/toolkit';
import { connectRouter } from 'connected-react-router';
import { History } from 'history';
import { loadingReducer } from './loading/reducer';
import { snackReducer } from './snack/reducer';
import { authReducer } from '../modules/auth/state/reducer';
import { baseReducer } from '../modules/base/state/reducer';
import { profileReducer } from '../modules/profile/state/reducer';
import { assetReducer } from '../modules/asset/state/reducer';
import { storageReducer } from '../modules/storage/state/reducer';

export const createRootReducer = (history: History<any>) =>
    combineReducers({
        router: connectRouter(history),
        loading: loadingReducer,
        auth: authReducer,
        snack: snackReducer,
        base: baseReducer,
        profile: profileReducer,
        asset: assetReducer,
        storage: storageReducer,
    });

export type RootState = ReturnType<ReturnType<typeof createRootReducer>>;
