import { makeStyles, Theme, MenuItem, Divider, Typography } from '@material-ui/core';
import React from 'react';
import { LogoutActionCreator } from '../../auth/state/actions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../state/rootReducer';

const withStyles = makeStyles((theme: Theme) => ({
    name: {
        padding: 16,
    },
    progress: {
        margin: theme.spacing(2),
    },
    grow: {
        flexGrow: 1,
    },
}));

export const UserMenu = () => {
    const classes = withStyles({});

    const user = useSelector((state: RootState) => state.profile.user);
    const dispatch = useDispatch();

    return (
        <>
            <div className={classes.name}>
                <Typography variant="h5" color="inherit" noWrap>
                    {`${user?.firstName} ${user?.lastName}`}
                </Typography>
                <Typography color="textSecondary" variant="subtitle1" noWrap>
                    {user?.email}
                </Typography>
            </div>
            <Divider />
            <div style={{ display: 'flex', flexDirection: 'column', minHeight: 100 }}>
                <div style={{ flex: 1, minHeight: 50 }} />
                <Divider />
                <MenuItem
                    button
                    onClick={() => {
                        dispatch(LogoutActionCreator());
                        window.location.pathname = '/';
                    }}
                >
                    <Typography variant="inherit">Log out</Typography>
                </MenuItem>
            </div>
        </>
    );
};
