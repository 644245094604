import React from 'react';

const titleContext = React.createContext<{ title: string; setTitle(title: string): void }>({ title: 'Resepti', setTitle: (_title: string) => null });

export const TitleHost: React.FunctionComponent = (props) => {
    const [title, setTitle] = React.useState('Resepti');

    return <titleContext.Provider value={{ title, setTitle }}>{props.children}</titleContext.Provider>;
};

export const ProvidePageTitle = (props: { children: string }): null => {
    const { setTitle } = React.useContext(titleContext);
    React.useEffect(() => {
        setTitle(props.children);
    }, [props.children, setTitle]);
    return null;
};

export const RenderPageTitle = (): JSX.Element => {
    const { title } = React.useContext(titleContext);
    return <span>{title}</span>;
};
