import { produce } from 'immer';
import { OptionsObject } from 'notistack';
import { SnackAction } from './actions';

export interface ISnack {
    message: string;
    options?: OptionsObject;
    key: string;
    dismissed?: boolean;
}

export interface ISnackState {
    notifications: Array<ISnack>;
}

const initialState: ISnackState = {
    notifications: [],
};

export const snackReducer = produce((draft: ISnackState, action: SnackAction) => {
    switch (action.type) {
        case 'ENQUEUE_SNACK': {
            draft.notifications.push(action.payload);
            break;
        }
        case 'CLOSE_SNACKBAR': {
            if (action.payload.key) {
                const single = draft.notifications.find((not) => not.key === action.payload.key);
                if (single) {
                    single.dismissed = true;
                }
            } else {
                for (const not of draft.notifications) {
                    not.dismissed = true;
                }
            }
            break;
        }
        case 'REMOVE_SNACKBAR': {
            draft.notifications = draft.notifications.filter((not) => not.key !== action.payload.key);
        }
    }
}, initialState);
