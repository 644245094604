import { all, call } from 'redux-saga/effects';
import createSagaMiddleware from 'redux-saga';
import { rootStore } from './store';
import { enqueueSnackbar } from './snack/actions';
import { loadingSagas } from './loading/sagas';
import { authSagas } from '../modules/auth/state/saga';
import { profileSagas } from '../modules/profile/state/sagas';

export function* rootSaga() {
    yield all([call(loadingSagas), call(authSagas), call(profileSagas)]);
}

export const sagaMiddleware = createSagaMiddleware({
    onError(err) {
        rootStore.dispatch(enqueueSnackbar({ message: `[INTERNAL]: ${err}`, options: { variant: 'error' } }));
        console.error(err);
    },
});
