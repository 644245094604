import { AppBar, Drawer, makeStyles, Theme, Typography, useTheme, useMediaQuery } from '@material-ui/core';
import clsx from 'clsx';
import React, { ReactChild, ReactNode } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../state/rootReducer';
import { ChevronRight, ChevronLeft } from '@material-ui/icons';
import { SetNavExpandedActionCreator } from '../state/actions';

export const drawerWidth = 180;

const withStyles = makeStyles((theme: Theme) => ({
    root: {
        display: 'flex',
        height: '100%',
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        boxShadow: 'none',
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        backgroundColor: theme.palette.background.default,
        borderWidth: 0,
        overflowX: 'hidden',
    },
    drawerPaperOpen: {
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
    },
    toolbar: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        width: `calc(100% - ${drawerWidth}px)`,
    },
    rootContent: {
        overflowY: 'auto',
        height: `calc(100% - ${theme.mixins.toolbar.minHeight}px)`,
    },
}));

interface IPageProps {
    topnav_children: ReactChild;
    drawer_menu_children: ReactChild;
    children: ReactNode;
}

const Page: React.ComponentType<IPageProps> = ({ topnav_children, drawer_menu_children, children }: IPageProps) => {
    const classes = withStyles({});
    const theme = useTheme();

    const navExpanded = useSelector((state: RootState) => state.base.nav.expanded);
    const dispatch = useDispatch();
    const is_small = useMediaQuery(theme.breakpoints.down('sm'));

    const onClickHandler = () => dispatch(SetNavExpandedActionCreator(!navExpanded));

    const drawer_contents = (
        <>
            <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                <div style={{ flex: 1, alignSelf: 'stretch' }}>{drawer_menu_children}</div>
                <div style={{ display: 'flex', padding: 20, cursor: 'pointer' }} onClick={onClickHandler} onKeyPress={onClickHandler} role="button" tabIndex={0}>
                    {navExpanded ? (
                        <>
                            <ChevronLeft style={{ fontSize: 20, marginRight: 10 }} />
                            <Typography variant="body1">Collapse</Typography>
                        </>
                    ) : (
                        <ChevronRight
                            style={{
                                fontSize: 20,
                            }}
                        />
                    )}
                </div>
            </div>
        </>
    );

    return (
        <div className={classes.root}>
            <AppBar position="fixed" className={classes.appBar}>
                {topnav_children}
            </AppBar>
            {is_small ? (
                <>
                    <Drawer variant="permanent" classes={{ paper: clsx(classes.drawer, classes.drawerPaperClose) }} className={clsx(classes.drawer, classes.drawerPaperClose)}>
                        <div className={classes.toolbar} />
                        {drawer_contents}
                    </Drawer>
                    <Drawer
                        anchor="left"
                        classes={{ paper: clsx(classes.drawer, classes.drawerPaperOpen) }}
                        open={navExpanded}
                        className={clsx(classes.drawer, classes.drawerPaperOpen)}
                    >
                        {drawer_contents}
                    </Drawer>
                </>
            ) : (
                <Drawer
                    variant="permanent"
                    className={clsx(classes.drawer, {
                        [classes.drawerPaperOpen]: navExpanded,
                        [classes.drawerPaperClose]: !navExpanded,
                    })}
                    classes={{
                        paper: clsx(classes.drawer, {
                            [classes.drawerPaperOpen]: navExpanded,
                            [classes.drawerPaperClose]: !navExpanded,
                        }),
                    }}
                    open={navExpanded}
                >
                    <div className={classes.toolbar} />
                    {drawer_contents}
                </Drawer>
            )}
            <main className={classes.content}>
                <div className={classes.toolbar} />
                <div className={classes.rootContent}>{children}</div>
            </main>
        </div>
    );
};

export { Page as MainLayout };
