import { actionCreator, ActionTypeHelper } from '../utils';

export interface ILoadingPayload {
    request: string;
}

export interface ILoadingErrorPayload extends ILoadingPayload {
    error: string;
}

export const StartLoadingActionCreator = actionCreator<'START_LOADING', ILoadingPayload>('START_LOADING');
export const DoneLoadingActionCreator = actionCreator<'LOADING_SUCCESS', ILoadingPayload>('LOADING_SUCCESS');
export const ErrorLoadingActionCreator = actionCreator<'LOADING_ERROR', ILoadingErrorPayload>('LOADING_ERROR');

export type LoadingAction = ReturnType<typeof StartLoadingActionCreator> | ReturnType<typeof DoneLoadingActionCreator> | ReturnType<typeof ErrorLoadingActionCreator>;

const delay = (ms: number) =>
    new Promise((res) => {
        setTimeout(res, ms);
    });

const cancelMap: { [key: string]: { cancel(): void; prom: Promise<any> } } = {};

export class CancellationError extends Error {}

export const WrapLoading = <T>(
    request: string,
    action: ActionTypeHelper<Promise<T>>,
    gate = true,
    debounce = true,
    propagateError = false,
    gateCancelError = false,
): ActionTypeHelper<Promise<T>> => async (dispatch, getState, extra) => {
    if (cancelMap[request] && gateCancelError) {
        cancelMap[request].cancel();
        delete cancelMap[request];
    } else {
        if (gate) {
            const alreadyLoading = getState().loading.actions[request]?.loading;
            if (alreadyLoading) {
                while (getState().loading.actions[request]?.loading) {
                    await delay(50);
                }
                if (debounce) {
                    return;
                }
            }
        }
    }
    cancelMap[request] = (() => {
        let cancelled = false;
        return {
            cancel: () => (cancelled = true),
            prom: (async () => {
                try {
                    dispatch(StartLoadingActionCreator({ request }));
                    const result = await action(dispatch, getState, extra);
                    if (cancelled) {
                        throw new CancellationError('cancelled');
                    } else {
                        dispatch(DoneLoadingActionCreator({ request }));
                        return result;
                    }
                } catch (e) {
                    if (!(e instanceof CancellationError)) {
                        dispatch(ErrorLoadingActionCreator({ request, error: '' + e }));
                    }
                    if (propagateError || e instanceof CancellationError) {
                        throw e;
                    } else {
                        console.error(e);
                    }
                }
            })(),
        };
    })();

    return cancelMap[request].prom;
};
