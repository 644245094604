import { GetMeQuery, GetMeQueryVariables, GetMe, GetUserQuery, GetUser, GetUserQueryVariables } from '../../../generated/graphql';
import { actionCreator, ActionTypeHelper } from '../../../state/utils';
import { WrapLoading } from '../../../state/loading/actions';
import { GetGraphQLClient } from '../../auth/state/actions';

export interface IIdentifyPayload {
    user: GetMeQuery['getSelf'];
}

export const IdentifyActionCreator = actionCreator<'IDENTIFY', IIdentifyPayload>('IDENTIFY');
export const GetUserActionCreator = actionCreator<'GET_USER', GetUserQuery['getUser']>('GET_USER');

export type ProfileActions = ReturnType<typeof IdentifyActionCreator> | ReturnType<typeof GetUserActionCreator>;

export const IDENTITY_LOADING_INDICATOR = 'identity';

export function FetchIdentity(): ActionTypeHelper<Promise<void>> {
    return WrapLoading(IDENTITY_LOADING_INDICATOR, async (dispatch, getState) => {
        const client = GetGraphQLClient()(dispatch, getState, undefined);
        const response = await client.query<GetMeQuery, GetMeQueryVariables>({
            query: GetMe,
        });
        const { ...user } = response.data.getSelf;

        console.log(`Hello there ${user.firstName}`);

        dispatch(
            IdentifyActionCreator({
                user,
            }),
        );
    });
}

export const GET_USER_LOADING_INDICATOR = 'get_user_loading';

export function GetUserCall(args: GetUserQueryVariables): ActionTypeHelper<Promise<GetUserQuery['getUser']>> {
    return WrapLoading(GET_USER_LOADING_INDICATOR, async (dispatch, getState) => {
        const client = GetGraphQLClient()(dispatch, getState, undefined);
        const response = await client.query<GetUserQuery, GetUserQueryVariables>({
            query: GetUser,
            variables: args,
        });
        if (response.errors) {
            throw new Error(response.errors.map((e) => e.message).join('\n'));
        }

        dispatch(GetUserActionCreator());
        return response.data.getUser;
    });
}
