import { replace } from 'connected-react-router';
import locationHelperBuilder from 'redux-auth-wrapper/history4/locationHelper';
import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect';
import { RootState } from '../../state/rootReducer';

export const ensureAuthenticated = connectedRouterRedirect<Record<string, unknown>, RootState>({
    redirectPath: '/login',
    // If returns false, do redirect
    // So: Only redirect when not logged in
    authenticatedSelector: (state) => state.auth.logged_in,
    wrapperDisplayName: 'EnsureAuthentication',
    // AuthenticatingComponent: CircularProgress,
    redirectAction: replace,
});

const locationHelper = locationHelperBuilder({});

export const handleLogin = connectedRouterRedirect<Record<string, unknown>, RootState>({
    redirectPath: (_state, ownProps) => locationHelper.getRedirectQueryParam(ownProps) || '/',
    redirectAction: replace,
    wrapperDisplayName: 'HandleLogin',
    allowRedirectBack: false,
    // If returns false, do redirect
    // So: Only redirect when logged in
    authenticatedSelector: (state) => !state.auth.logged_in,
});
