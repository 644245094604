import { produce } from 'immer';
import { ProfileActions } from './actions';
import { GetMeQuery } from '../../../generated/graphql';

export interface IProfileState {
    user: GetMeQuery['getSelf'] | null;
}

const initialState: IProfileState = {
    user: null,
};

export const profileReducer = produce((draft: IProfileState, action: ProfileActions) => {
    switch (action.type) {
        case 'IDENTIFY': {
            draft.user = action.payload.user;
            break;
        }
    }
}, initialState);
