import { createBrowserHistory } from 'history';
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { routerMiddleware } from 'connected-react-router';
import { createRootReducer } from './rootReducer';
import { sagaMiddleware, rootSaga } from './rootSaga';
import { mergedInitialActions } from './initialActions';
import { useDispatch } from 'react-redux';

export const history = createBrowserHistory();

const default_middleware = getDefaultMiddleware({ serializableCheck: false, immutableCheck: false, thunk: true });

export const rootStore = configureStore({
    reducer: createRootReducer(history),
    middleware: [...default_middleware, routerMiddleware(history), sagaMiddleware].filter(Boolean) as typeof default_middleware,
});

sagaMiddleware.run(rootSaga);

for (const action of mergedInitialActions) {
    rootStore.dispatch(action as any);
}

export type AppDispatch = typeof rootStore.dispatch;
export const useStoreDispatch = () => useDispatch<typeof rootStore.dispatch>();
