import { produce } from 'immer';
import { BaseActions } from './actions';

export interface INavItem {
    href?: string;
    id: string;
    parent_id?: string;
    sort_order?: number;
    enabled?: boolean;
    disable_icon?: boolean;
    title: string;
    icon?: string;
    children: string[];
    divided?: boolean;
}

export interface IBaseState {
    nav: {
        itemsById: Record<string, INavItem>;
        rootIds: string[];
        expanded: boolean;
    };
}

const initItemsById: IBaseState['nav']['itemsById'] = {
    searchAssets: {
        href: '/searchAssets',
        id: 'searchAssets',
        title: 'Search',
        icon: 'dashboard',
        children: [],
        sort_order: 0,
    },
    createAsset: {
        href: '/assets/createAsset',
        id: 'createAsset',
        title: 'Create Asset',
        icon: 'add',
        children: [],
        sort_order: 1,
    },
    assetNodeView: {
        href: '/assets/assetTree',
        id: 'assetNodeView',
        title: 'Asset Tree',
        icon: 'tree',
        children: [],
        sort_order: 2,
    },
};

const initialState: IBaseState = {
    nav: {
        itemsById: initItemsById,
        rootIds: ['searchAssets', 'createAsset', 'assetNodeView'],
        expanded: true,
    },
};

export const baseReducer = produce((draft: IBaseState, action: BaseActions) => {
    switch (action.type) {
        case 'SET_NAV_EXPANDED': {
            draft.nav.expanded = action.payload;
            break;
        }
    }
}, initialState);
