import { all, call, put, takeEvery } from 'redux-saga/effects';
import { SetAuthTokenActionCreator, LogoutActionCreator } from './actions';

export const TOKEN_KEY = 'AUTH_TOKEN';

function* tokenPersistence() {
    const val = window.localStorage.getItem(TOKEN_KEY);
    if (val) {
        yield put(SetAuthTokenActionCreator(JSON.parse(val)));
    }
    yield all([
        takeEvery('' + SetAuthTokenActionCreator, function (data: any) {
            window.localStorage.setItem(TOKEN_KEY, JSON.stringify(data.payload));
        }),
        takeEvery('' + LogoutActionCreator, function () {
            window.localStorage.removeItem(TOKEN_KEY);
        }),
    ]);
}

export function* authSagas() {
    yield all([call(tokenPersistence)]);
}
