import { ThunkAction } from 'redux-thunk';

import { RootState } from './rootReducer';

export interface IActionType<type extends string, payload> {
    type: type;
    payload: payload;
}

export type ActionTypeHelper<T> = ThunkAction<T, RootState, undefined, any>;

export const actionCreator = <KType extends string, PType = undefined>(type: KType) => {
    const actionCreator = (payload?: PType): IActionType<KType, PType> => ({
        type,
        payload: payload as any,
    });

    actionCreator.toString = () => type;

    return actionCreator;
};

type DeepWriteable<T> = { -readonly [P in keyof T]: DeepWriteable<T[P]> };

export function makeWritable<T>(val: T): DeepWriteable<T> {
    return val;
}

// eslint-disable-next-line @typescript-eslint/ban-types
type ImmutablePrimitive = undefined | null | boolean | string | number | Function;
export type Immutable<T> = T extends ImmutablePrimitive
    ? T
    : T extends Array<infer U>
    ? ImmutableArray<U>
    : T extends Map<infer K, infer V>
    ? ImmutableMap<K, V>
    : T extends Set<infer M>
    ? ImmutableSet<M>
    : ImmutableObject<T>;
export type ImmutableArray<T> = ReadonlyArray<Immutable<T>>;
export type ImmutableMap<K, V> = ReadonlyMap<Immutable<K>, Immutable<V>>;
export type ImmutableSet<T> = ReadonlySet<Immutable<T>>;
export type ImmutableObject<T> = { readonly [K in keyof T]: Immutable<T[K]> };
