import { IconButton, makeStyles, Theme, Toolbar, useTheme, Popover, Typography, LinearProgress, Divider, useMediaQuery } from '@material-ui/core';
import React from 'react';
import {
    KeyboardArrowDown as ChevronDownIcon,
    Notifications as NotificationsIcon,
    AccountCircle as AccountCircleIcon,
    Close as CloseIcon,
    MoreHoriz as HorizMoreIcon,
} from '@material-ui/icons/';

import { useSelector } from 'react-redux';
import { RootState } from '../../../state/rootReducer';
import { RenderPageTitle } from '../title';
import { UserMenu } from './UserMenu';

const withStyles = makeStyles((_theme: Theme) => ({
    toolbar: {
        paddingRight: 10,
        paddingLeft: 10,
    },
    logo: {
        width: 40,
    },
    logo_text: {
        height: 14,
        paddingLeft: 10,
    },
    grow: {
        flexGrow: 1,
    },
}));

export const TopNav = () => {
    const [profile_menu_open, set_profile_menu_open] = React.useState(false);
    const [small_expanded, set_small_expanded] = React.useState(false);
    const [menu_anchor, set_menu_anchor] = React.useState(null);
    const theme = useTheme();
    const classes = withStyles({});

    const is_small = useMediaQuery(theme.breakpoints.down('sm'));

    React.useEffect(() => {
        if (!is_small) {
            set_small_expanded(false);
        }
    }, [is_small]);

    const anyLoading = useSelector((state: RootState) => state.loading.anyLoading);

    // const darkMode = false;

    // const dispatch = useDispatch();

    return (
        <>
            <Toolbar className={classes.toolbar} disableGutters>
                {/* {theme.palette.type === 'dark' ? (
                    <>
                        <img className={classes.logo} alt="" src={require('assets/narwhal_logo_only_white.png')} />
                        {!is_small && <img className={classes.logo_text} alt="" src={require('assets/text_only_white.png')} />}
                    </>
                ) : (
                    <>
                        <img className={classes.logo} alt="" src={require('assets/narwhal_logo_only.png')} />
                        {!is_small && <img className={classes.logo_text} alt="" src={require('assets/text_only.png')} />}
                    </>
                )} */}
                {!small_expanded && (
                    <>
                        <div className={classes.grow} />
                        <Typography noWrap style={{ margin: '0 5px' }} variant="h6">
                            <RenderPageTitle />
                        </Typography>
                        <div className={classes.grow} />
                    </>
                )}
                {((is_small && small_expanded) || !is_small) && (
                    <>
                        <div style={is_small ? { width: '100%', display: 'flex', justifyContent: 'space-evenly' } : {}}>
                            {/* <IconButton color="inherit" onClick={() => dispatch(SetDarkModeActionCreator(!darkMode))}>
                                {darkMode ? <MoonIcon /> : <SunIcon />}
                            </IconButton> */}

                            <IconButton
                                color="inherit"
                                disabled
                                onClick={() => alert('notification menu!')}
                                buttonRef={(el) => {
                                    if (el && !menu_anchor) {
                                        set_menu_anchor(el as any);
                                    }
                                }}
                            >
                                <NotificationsIcon />
                            </IconButton>
                            <IconButton
                                color="inherit"
                                onClick={() => set_profile_menu_open(true)}
                                buttonRef={(el) => {
                                    if (el && !menu_anchor) {
                                        set_menu_anchor(el as any);
                                    }
                                }}
                            >
                                <AccountCircleIcon /> <ChevronDownIcon />
                            </IconButton>
                        </div>
                        {is_small && <div style={{ flexGrow: 1 }} />}
                    </>
                )}
                {is_small && (
                    <IconButton color="inherit" size="small" onClick={() => set_small_expanded(!small_expanded)}>
                        {small_expanded ? <CloseIcon /> : <HorizMoreIcon />}
                    </IconButton>
                )}
                <Popover
                    id="profile_menu"
                    open={profile_menu_open}
                    anchorEl={menu_anchor}
                    onClose={() => set_profile_menu_open(false)}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    <UserMenu />
                </Popover>
            </Toolbar>
            {anyLoading ? <LinearProgress color="secondary" style={{ height: 2, width: '100%' }} /> : <Divider style={{ width: '100%' }} />}
        </>
    );
};
